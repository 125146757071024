//////////////////////////////////////////////
/// 1em = 16px Ex: 576 / 16
// 1em mesurement works better for browsers
/// MEDIA QUIERIE MANAGER
$xs: 0;
$sm: 35em; // 576px
$md: 51.25em; // 820px
$lg: 62em; // 992px
$xl: 81.25em; //1300px
$xxl: 93.75em; // 1500px
$xxxl: 125em; //2000px
// @mixin reusability for media use
// @content allow to input an entire code block
// @breakpoint is the parameter being passed
// from larger to small order for media to work properly
@mixin response-media($breakpoint) {
  @if ($breakpoint == xxxlarge) {
    @media (max-width: $xxxl) {
      @content;
    } // 2000px
  } @else if ($breakpoint == xxlarge) {
    @media (max-width: $xxl) {
      @content;
    } // 1500px
  } @else if ($breakpoint == xlarge) {
    @media (max-width: $xl) {
      @content;
    } // 1300px
  } @else if($breakpoint == large) {
    @media (max-width: $lg) {
      @content;
    } // 992px
  } @else if($breakpoint == medium) {
    @media (max-width: $md) {
      @content;
    } // 820px
  } @else if($breakpoint == small) {
    @media (max-width: $sm) {
      @content; //576px
    }
  }
}

@mixin events-container() {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

@mixin events-single-wrapper() {
  width: 22em;
  height: auto;
  border: 1px solid black;
  margin: 0.5em;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  box-shadow: 2px 2px 4px rgb(99, 99, 99);
  img {
    width: 5em;
    height: 3em;
  }
}
@mixin sidebar-scroll() {
  &::-webkit-scrollbar {
    width: 0.3em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: gray;
  }
}
@mixin event-style-indicator(){
  width: 1em;
  height: 1em;
  border-radius: 1.5em;
}
