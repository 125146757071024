.card-container {
  width: 20em;
  height: 14em;
  // border: 1px solid black;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  margin: 0.5em;

  img {
    height: 14em;
    width: 5em;
  }
  h4 {
    width: auto;
    // border: 2px solid red;
    margin: 0.2em;
    font-size: .8em;
  }
  span{
    color: rgb(173, 89, 21);
    text-transform: uppercase;
  }

}
.card-wrapper {
  width: 13em;
  height: 14em;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
