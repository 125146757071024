@import '../../libs/events-mixin';

.storage-management-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 1em;

}


////////////////////////////////////
/// StorageGetAllFiles.js styles 
/// 
.storage-get-all-files-button-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.storage-get-all-files-container{
    @include events-container();
    height: 65vh;
    overflow-y: auto;
    @include sidebar-scroll();
    // @include response-media(medium){
    //     width: 100%;
        
    //   }
}

.storage-get-all-files-single-wrapper{
    @include events-single-wrapper();
    width: 16em;
}