.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.main-menu-wrapper {
  width: 100%;
  height: 6em;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 2px outset;
  background: white;
}
.main-content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  margin-top: 7em;
}
///////////////////////////////////
/// Update.js

.update-container{
  width: 100%;
  height: 100%;
}

.update-cards-container{
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1em;

}

.update-search-container{
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: center;
  margin-top: .5em;
}