body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    width: 0.6em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: gray;
  }
  &::-webkit-scrollbar-track{
    background: rgb(207, 207, 207);
  }
}


