@import "../../../libs/events-mixin";

.messages-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: wrap;
}
.messages-new {
  width: 100%;
  display: flex;
  flex-flow: wrap;
}
.messages-single-item-wrapper {
  @include events-single-wrapper();
  h6, h5, h4 {
    margin: 0;
    margin-bottom: .5em;
  }
}

