@mixin button-mixin-style($width_) {
  width: $width_;
  height: 3.5em;
  border: 1px solid gray;
  background-color: white;
  color: black;
  margin: 0.5em;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  font-size: .65em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
 
  @content;
}

@mixin hover-button {
  transition: color 0.6s ease-in-out, background-color 0.6s ease-in-out,
  border 0.6s ease-in-out;
  &:hover {
    border: none;
    background-color: gray;
    color: white;
  }
}
