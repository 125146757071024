@import "../../../libs/events-mixin";

//////////////////////////////
/// Events.js
.events-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  // align-items: center;
}
.events-subcontainer-one {
  width: 45%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  @include response-media(medium) {
    width: 50%;
  }
  @include response-media(small) {
    width: 90%;
  }
}
.events-subcontainer-two {
  width: 40%;
  height: 100vh;
  overflow: hidden;

  @include response-media(medium) {
    width: 100%;
  }
}

.events-file-input-wrapper {
  width: 100%;
  height: auto;
  display: flex;
}
.events-buttons-wrapper {
  width: 100%;
  height: 5em;
  display: flex;
  justify-content: space-evenly;

  align-items: center;
}

////////////////////////////////
/// EventsDownload.js style
.events-download-container {
  @include events-container();
  height: 55vh;
  // border: 2px solid red;
  overflow-y: auto;
  @include sidebar-scroll();
  @include response-media(medium) {
    width: 100%;
    height: 50vh;
  }
}
.events-download-single-wrapper {
  overflow: hidden;
  // events single wrapper increases automatically
  @include events-single-wrapper();
  h4,
  h5,
  h6 {
    margin: 0;
    margin-bottom: 0.3em;
  }
  display: flex;
  flex-flow: row;
}
.events-download-info-wrapper {
  text-align: center;
}
.events-download-search-wrapper {
  display: flex;
  height: 5em;
  flex-flow: column;
  align-items: center;
  h5 {
    margin: 0;
    margin-bottom: 0.2em;
  }
}
.events-download-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
////////////////////////////////////////
///  event indicator - posted / not posted yet / event past due
///

.events-indicator-container {
  width: 100;
  padding-left: 1em;
  border-bottom: 1px solid black;
  .events-indicator-wrapper {
    display: flex;
  }
  span {
    margin-left: 0.5em;
  }
}
.events-indicator-red {
  @include event-style-indicator();
  background-color: red;
}
.events-indicator-green {
  @include event-style-indicator();
  background-color: green;
}
.events-indicator-yellow {
  @include event-style-indicator();
  background-color: rgb(205, 205, 67);
}
