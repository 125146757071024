@import "../../libs/events-mixin";

.notification-system-container {
  width: 95%;
  height: auto;
//   border: 2px solid red;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  @include response-media(medium) {
    width: 100%;
    margin-top: 2em;
  }


  input.notification-message{
    background-color: transparent;
    height: 2em;
    width: 17em;
  }
}
