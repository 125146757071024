@import "../../libs/form-profile";
@import "../../libs/events-mixin";

////////////////////////////////
/// FormEvent
.formtodatabase-container {
  width: 90%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  form {
    width: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  @include response-media(medium) {
    width: 95%;
  }
}
.formtodatabase-form-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;

  label {
    // width: 17.1em;
    text-align: center;
  }
}

.formtodatabase-label {
  width: 16.4em;
  display: flex;
  justify-content: space-between;
  // align-items: center;
}
.form-layout {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
////////////////////////////////
/// FormProfile
.form-profile-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.form-profile-wrapper {
  width: 50%;
  display: flex;
  flex-flow: column;

  textarea {
    resize: none;
    height: 8em;
    overflow: auto;
    margin-bottom: 0.5em;
    border-radius: 1em;
    background: #f7f7f7;
    border: none;
  }
  input {
    width: 6em;
    height: 3em;
    text-transform: uppercase;
    background: transparent;
    border: 1px solid black;
    margin-top: 2em;
    margin-bottom: 0.5em;
    border-radius: 1em;
  }
}
////////////////////////////////
/// FormProfileInfo
.form-profile-button-wrapper {
  width: 100%;
  height: 7em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-profile-display-wrapper {
  @include events-container();
}
.form-profile-single-container {
  // parametor control the color in border css property
  @include form-profile-single-container(black);
}
.form-profile-single-container-red {
  @include form-profile-single-container(red);
}
.form-profile-buttons-container {
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  background: white;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

//////////////////////////////////
/// FormUpdate
.form-update-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  h4 {
    width: 100%;
    height: 1em;
    text-align: center;
    text-transform: capitalize;
    color: rgb(82, 119, 82)
  }
  h5{
    margin: 1em;
    width: 8em;
    // border: 2px solid red;

  }
  form{
    width: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    // align-items: center;
    // border: 2px solid red;
    @include response-media(small){
      width: 90%
    }
  }
}

//////////////////////////////////
/// Inputs styles
.input-button-wrapper {
  @include input-style();
  width: 10em;
  background: rgb(87, 133, 87);
  text-transform: uppercase;
  color: white;
  margin-top: 0.5em;
  border: none;
  cursor: pointer;
}

.input-time-wrapper {
  @include input-style();
  width: 8em;
}
.input-url-wrapper {
  @include input-style();
  width: 15em;
}
.input-text-wrapper {
  @include input-style();
  width: 23.5em;
  // @include response-media(small){
  //   width: 15em;
  // }
}
