

@mixin form-profile-single-container($color){
    width: 23em;
    height: 19em;
    border: 2px solid $color;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
    margin: .5em;
    display: flex;
    flex-flow: column;
    align-items: center;
}

// change the width based on the layout 
@mixin input-style(){
    background: white;
    height: 2.5em;
    border: 1px solid black;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}