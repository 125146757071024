@import "../../libs/button-mixin";

.button-small-style {
   // parametor controls the width
  @include button-mixin-style(7.5em) {
    @include hover-button();
  }
}

.button-medium-style {
  // parametor controls the width
  @include button-mixin-style(12em) {
    @include hover-button();
  }
}

.button-medium-highlighted {
  // parametor controls the width
  @include button-mixin-style(12em) {
    @include hover-button();
    border: 2px solid rgb(153, 14, 51);
    color: rgb(153, 14, 51);
    border-radius: .5em;
    transition: all .5s ease-in-out;
  }
}
.button-medium-highlighted-word {
  // parametor controls the width
  @include button-mixin-style(12em) {
    @include hover-button();
    color: rgb(153, 14, 51);
    border-radius: .5em;
    transition: all .5s ease-in-out;
  }
}
.button-small-highlighted {
  // parametor controls the width
 @include button-mixin-style(7em) {
   @include hover-button();
   border: 2px solid rgb(14, 14, 153);
    border-radius: .5em;
    transition: all .5s ease-in-out;
 }
}
